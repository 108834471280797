export const HOME_PAGE = '/';
export const LOGIN_PAGE = '/auth/sign-in'
export const SIGNUP_PAGE = '/auth/sign-up'
export const PRODUCTS_PAGE = '/products'


export const LOGIN_ENDPOINT = '/login'
export const SIGNUP_ENDPOINT = '/signup'
export const GET_AUTH_REQ = '/getauth'
export const LOGOUT_REQ = '/logout'
export const GET_USERS_ENDPOINT = '/users'
export const ADD_CATEGORY_ENDPOINT = '/addcategory'
export const GET_CATEGORY_ENDPOINT = '/categories'
export const ADD_PRODUCT_ENDPOINT = '/addproduct'
export const GET_PRODUCT_ENDPOINT = '/products'
export const ADD_BEST_SELLER_ENDPOINT = '/addbestseller'
export const ADD_NEW_SELLER_ENDPOINT = '/addnewseller'
export const GET_CART = '/getcart'
export const UPDATE_CART = '/updatecart'
export const DELETE_FROM_CART = '/deletefromcart'